import { animated } from '@react-spring/web'
import { observer } from 'mobx-react'
import type { FunctionComponent } from 'react'
import type { Aria } from 'react-modal'

import type { ModalSize, ModalState } from '../../lib/store/ui/modal'
import { modalStore } from '../../lib/store/ui/modal'
import { Box } from '../box'
import { Button } from '../button/button'
import { Heading } from '../heading'
import { Close } from '../icons/close'
import { Portal } from '../portal'
import { useModalBounce } from './hooks'
import styles from './modal.module.scss'

/**
 * A window that blocks everything until a certain action is performed.
 */
export const ModalContainer: FunctionComponent<
    React.PropsWithChildren<ContainerProps>
> = ({
    children,
    visible,
    onHide,
    aria,
    hasCloseButton = true,
    size = 'small',
}) => {
    const { overlay, container } = useModalBounce(visible)

    return (
        <Portal
            ariaHideApp
            shouldCloseOnEsc
            shouldCloseOnOverlayClick
            shouldReturnFocusAfterClose
            isOpen={visible}
            onRequestClose={onHide}
            role='dialog'
            aria={aria}
            overlayElement={(props, children) => (
                <animated.div
                    {...props}
                    style={overlay}
                    className={styles.overlay}
                >
                    {children}
                </animated.div>
            )}
            contentElement={(props, children) => (
                <animated.div
                    {...props}
                    className={styles.container}
                    style={container}
                >
                    {hasCloseButton && (
                        <Button
                            preset='plain'
                            animatePress={false}
                            size='medium'
                            className={styles.close}
                            onClick={onHide}
                        >
                            <Close className={styles.x} />
                        </Button>
                    )}
                    <Box rounded shadow className={styles.dialog} pad={[]}>
                        <div className={styles[size]}>{children}</div>
                    </Box>
                </animated.div>
            )}
        >
            {children}
        </Portal>
    )
}

interface ContainerProps {
    size?: ModalSize

    /**
     * Whether the modal is visible.
     */
    visible: boolean

    /**
     * Callback for when the modal is going into hiding 🕵️
     */
    onHide?: () => void

    /**
     * Additional aria attributes for the modal.
     */
    aria?: Aria

    /**
     * Whether to show the X button.
     */
    hasCloseButton?: boolean
}

export const Modal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
    id,
    name,
    title = '',
    body = '',
    secondary = '',
    primary = '',
    visible,
    size,
    onHide,
    onPrimary,
    onSecondary,
    hasCloseButton,
    className,
}) => {
    const ids = {
        title: `${id}-title`,
        body: `${id}-body`,
    }

    return (
        <ModalContainer
            visible={visible}
            onHide={onHide}
            size={size}
            aria={{ labelledby: ids.title, describedby: ids.body }}
            hasCloseButton={hasCloseButton}
        >
            <div className={className}>
                <Heading level={4} id={ids.title} className={styles.heading}>
                    {title}
                </Heading>
                <div
                    id={ids.body}
                    className={styles.body}
                    data-testid='modal.body'
                >
                    {body}
                </div>
                <footer className={styles.footer}>
                    {secondary && (
                        <Button
                            size='medium'
                            preset='secondary'
                            onClick={onSecondary}
                            className={styles.secondary}
                            data-testid='modal.secondary'
                            analyticsKey={name?.concat('.secondary')}
                        >
                            {secondary}
                        </Button>
                    )}
                    <Button
                        size='medium'
                        preset='primary'
                        onClick={onPrimary}
                        className={styles.primary}
                        data-testid='modal.primary'
                        analyticsKey={name?.concat('.primary')}
                    >
                        {primary}
                    </Button>
                </footer>
            </div>
        </ModalContainer>
    )
}

/**
 * MobX wrapper for the component that toggles it and sets content.
 * This should only be rendered once in the app.
 */
export const ObservedModal: FunctionComponent<{ className?: string }> =
    observer(({ className }) => {
        const { id, name, content, visible, onHide, onPrimary, onSecondary } =
            modalStore

        return (
            <Modal
                className={className}
                {...content}
                id={id}
                name={name}
                visible={visible}
                onHide={onHide}
                onPrimary={onPrimary}
                onSecondary={onSecondary}
            />
        )
    })

export default ObservedModal

type ModalProps = Omit<ModalState, 'content'> &
    ModalState['content'] & { className?: string }
